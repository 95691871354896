var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"case"},[_vm._l((_vm.pageAd),function(item,index){return _c('img',{key:index,attrs:{"src":_vm.serverUrl + item.savePath,"alt":""}})}),_c('div',{staticClass:"tabList"},[_c('div',{staticClass:"tabs-wrap"},[_c('div',{staticClass:"tabs"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticClass:"item",class:_vm.tabIndex === index ? 'active' : '',on:{"click":function($event){return _vm.selectTabItem(index)}}},[_vm._v(_vm._s(item)+" ")])}),0)]),_c('div',{staticClass:"tabs-content"},[(_vm.tabIndex === 0)?_c('div',{staticClass:"first-tab"},[_vm._m(0),_c('div',{staticClass:"case-content"},[_c('swiper',{ref:"caseSwiper",staticClass:"case-content",attrs:{"options":{
                                                                  slidesPerView: 1,
                                                                  loop: true,
                                                                  autoplay: false,
                                                                  allowTouchMove: true,
                                                                  observer: true,
                                                                  observeParents: true,
                                                                  spaceBetween: 0,
                                                                }}},[_vm._l((_vm.dataList),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"job-item",domProps:{"innerHTML":_vm._s(item.content)}})])}),_c('div',{staticClass:"swiper-button-prev",staticStyle:{"color":"#333333","z-index":"999"},attrs:{"slot":"button-prev"},on:{"click":function($event){return _vm.swiperNext("caseSwiper")}},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",staticStyle:{"color":"#333333","z-index":"999"},attrs:{"slot":"button-next"},on:{"click":function($event){return _vm.swiperPrev("caseSwiper")}},slot:"button-next"})],2)],1)]):_vm._e()])]),_c('div',{staticClass:"tab-bottom"})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"first-tab"},[_c('p',{staticClass:"title"},[_vm._v("人才招聘")])])}]

export { render, staticRenderFns }