<template>
  <div class="case">
    <img v-for="(item,index) in pageAd" :key="index" :src="serverUrl + item.savePath" alt="">
    <div class="tabList">
      <div class="tabs-wrap">
        <div class="tabs">
          <div class="item" :class="tabIndex === index ? 'active' : ''" v-for="(item,index) in tabs"
               :key="index" @click="selectTabItem(index)">{{ item }}
          </div>
        </div>
      </div>

      <div class="tabs-content">
        <div class="first-tab" v-if="tabIndex === 0">
          <div class="first-tab">
            <p class="title">人才招聘</p>
          </div>
          <div class="case-content">
            <swiper class="case-content" ref="caseSwiper" :options="{
                                                                  slidesPerView: 1,
                                                                  loop: true,
                                                                  autoplay: false,
                                                                  allowTouchMove: true,
                                                                  observer: true,
                                                                  observeParents: true,
                                                                  spaceBetween: 0,
                                                                }">
              <swiper-slide v-for="(item, index) in dataList" :key="index">
                <div class="job-item" v-html="item.content">
<!--                  <div class="job">岗位：{{ item.job }}</div>-->
<!--                  <div class="question">要求：{{ item.question }}</div>-->
<!--                  <div class="question"> 岗位职责： {{ item.questions }}</div>-->
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev" style="color: #333333;z-index: 999"
                   @click='swiperNext("caseSwiper")'></div>
              <div class="swiper-button-next" slot="button-next" style="color: #333333;z-index: 999"
                   @click='swiperPrev("caseSwiper")'></div>
            </swiper>
          </div>
        </div>
      </div>

    </div>
    <div class="tab-bottom"></div>
  </div>
</template>

<script>
import {getAd, getArts} from "@/api/api";
import {serverUrl} from "@/utils/config";

export default {
  name: "Index",
  data() {
    return {
      serverUrl: serverUrl,
      activeName: "first",
      dataList: [],
      currentPage: 1, //初始页
      pageSize: 8,    //    每页的数据
      paginationList: [],
      tabs: ["人才招聘"],
      tabIndex: 0,
      pageAd: []
    };
  },
  created() {
    this.getData();
    this.getPageAd();
  },
  methods: {
    getPageAd(){
      getAd({
        code: 'jobs_ad'
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.pageAd = data;
        }
      })
    },
    getData() {
      getArts({
        categoryId: 10,
        pagination: false,
        containsContent: true
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
        this.dataList = data.rows;
        }
      })
    },
    swiperNext(ref) {
      this.$refs[ref].$swiper.slideNext()
    },
    swiperPrev(ref) {
      this.$refs[ref].$swiper.slidePrev()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
